import { useState } from 'react';

const TOKEN_KEY = 'auth';

interface UseUserResult {
    authToken: string | undefined;
    isLoading: boolean;
    error: any;
    handleIframeLoad: () => void;
}

export const useAuth = (): UseUserResult => {
    const [authToken, setAuthToken] = useState<string | undefined>(() => {
        return localStorage.getItem(TOKEN_KEY) || undefined;
    });

    const [isIframeLoading, setIsIframeLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);

    const handleIframeLoad = () => {
        setIsIframeLoading(false);
    };

    return {
        authToken,
        isLoading: isIframeLoading,
        error,
        handleIframeLoad,
    };
};
