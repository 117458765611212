import React, { FC, useState } from 'react';
import {
    CanAccess,
    ITreeMenu,
    useLogout,
    useRouterContext,
    useTranslate,
    useMenu,
} from '@pankod/refine-core';
import { Grid, Menu as AntMenu } from '@pankod/refine-antd';

import styles from './Menu.module.scss';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../hooks/useAuth';

export const Menu: FC = () => {
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const { t } = useTranslation();

    const { mutate: logout } = useLogout();

    const { Link } = useRouterContext();
    const { SubMenu } = AntMenu;

    const translate = useTranslate();
    const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
    const { authToken } = useAuth();

    const breakpoint = Grid.useBreakpoint();

    const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
        return tree.map((item: ITreeMenu) => {
            const { label, route, name, children } = item;

            if (children?.length > 0) {
                return (
                    <SubMenu key={name} title={label}>
                        {renderTreeView(children, selectedKey)}
                    </SubMenu>
                );
            }
            const isSelected = route === selectedKey;

            return (
                <CanAccess
                    key={route}
                    resource={name?.toLowerCase()}
                    action="list"
                >
                    <AntMenu.Item
                        key={route}
                        style={{
                            fontWeight: isSelected ? 'bold' : 'normal',
                        }}
                    >
                        <Link to={route}>{label}</Link>
                        {!collapsed && isSelected && (
                            <div className="ant-menu-tree-arrow" />
                        )}
                    </AntMenu.Item>
                </CanAccess>
            );
        });
    };

    const userMenuItems = authToken
        ? menuItems
        : [
              {
                  key: 'login',
                  name: 'login',
                  route: '/login',
                  icon: 'login-icon',
                  label: t('pages.login.signin'),
                  children: [],
              },
          ];

    return (
        <AntMenu
            selectedKeys={[selectedKey]}
            defaultOpenKeys={defaultOpenKeys}
            mode="horizontal"
            onClick={() => {
                if (!breakpoint.lg) {
                    setCollapsed(true);
                }
            }}
            selectable={false}
            className={styles.headerMenu}
        >
            {renderTreeView(userMenuItems, selectedKey)}
            {authToken && (
                <AntMenu.Item key="logout" onClick={() => logout()}>
                    {translate('buttons.logout', 'Logout')}
                </AntMenu.Item>
            )}
        </AntMenu>
    );
};
